import { GpayMethodType } from '@/types/common/index';
/** 클라우드 신청 가능여부 성공시 리턴값  */
export interface CloudCertifyCheck {
  user: {
    user_id: string;
    hanname: string;
    hanorg: string | null;
    hanadmin: string;
    gubun: '0' | '1';
    email: string;
    phone: string;
    hp_no: string;
  };
}

/** 클라우드 신청 전 유저등록정보와 Nice 본인인증의 정보를 대조함 */
export interface CloudGabiaAuth {
  RT_CODE: string;
  MSG: string | null;
  /** encrpt 된 인증정보가 들어있음 */
  DATA: string;
}

/** Nice 신용정보 확인 모듈 실패시 클라우드에서 주는 에러리턴 코드 */
export const enum NiceErrorCode {
  U9999 = '잘못된 접근입니다.',
  U0001 = '업체로부터 정보를 못받았습니다.',
  U0002 = '인증 정보가 일치하지 않습니다.',
  U0003 = '소유자 정보와 일치하지 않습니다. 기재하신 정보가 맞는지 다시 한번 확인해 주세요.',
  S0001 = '회원정보를 가져올 수 없습니다. 잠시 후 다시 시도해 주시기 바랍니다.',
}

/**
 * CloudRegistInfo : 자동결제를 사용하지 않는 경우의 클라우드 서비스 신청 정보
 */
export interface CloudRegistInfo {
  /** 가비아 로그인 세션키값  */
  gasess: string;
  /** 소유자 인증 성공/실패 세션키 값 */
  authsess: string;
  /** 자동 / 수동 결제  (자동결제의 경우 AutoPayInfo 필요)*/
  auto_settle: 'Y' | 'N';
}

/**
 * CloudRegistInfo : 자동결제를 사용하는 경우의 클라우드 서비스 신청 정보 (auto_settle Y 인 경우)
 */
export type CloudRegistInfoWithAutoPayInfo = CloudRegistInfo & {
  /** 정기결제에 등록한 seqno, auto_settle 값이 Y 이면 필수로 넘겨야 됨 */
  autopay_seqno: string;
  /** 정기결제에 등록한 결제 방식, auto_settle 값이 Y 이면 필수로 넘겨야 됨 */
  autopay_method: GpayMethodType;
};

export type CloudRegist = CloudRegistInfo | CloudRegistInfoWithAutoPayInfo;
